var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard-wrapper"
  }, [_c('Header', {
    attrs: {
      "user-dashboards": _vm.dashboardIds
    },
    on: {
      "openAddWidget": _vm.openAddWidgetModal,
      "dashboardUpdated": _vm.getDashboards
    }
  }), _c('Content', {
    attrs: {
      "widget-modal-opened": _vm.addWidgetModalOpened,
      "user-dashboard": _vm.userDashboard
    },
    on: {
      "closeModal": _vm.handleCloseModal,
      "updateDashboard": _vm.getDashboards
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }