<template>
  <div class="widgets-wrapper" :class="widgetClass">
    <!-- Header -->
    <div class="widget-header-wrapper">
      <div class="widget-header">
        <div class="widget-name">{{ this.$t(widget.name) }}</div>
        <div class="widget-buttons">
          <div class="widget-filter">
            <slot name="widget-filter"></slot>
          </div>
          <div
            class="widget-enlarge widget-button"
            @click="$emit('enlargeWidget', $event, widget)"
          >
            <img
              class="widget-btn"
              :src="require('@/assets/images/expand_grey.svg')"
              alt=""
            />
          </div>
          <div class="widget-options widget-button" @click="toggleMenu">
            <img
              class="widget-btn"
              :src="require('@/assets/images/dot_menu_grey.svg')"
              alt=""
            />
            <div
              class="widget-menu"
              v-if="dropdownOpen"
              v-click-outside="closeMenu"
            >
              <div
                v-for="(item, index) in menuItems"
                :key="'menu-item-' + index"
                class="menu-item"
                @click="() => selectItem(item.name)"
              >
                <img
                  :src="require(`../../assets/images/${item.iconUrl}`)"
                  alt=""
                  class="img16"
                />
                <div class="content">
                  {{ $t(item.field) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="widget-filters">
        <slot name="widget-filters"></slot>
      </div>
    </div>
    <div class="widget-content">
      <slot name="widget-content"></slot>
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";

export default {
  components: {},
  props: {
    widget: {
      type: Object,
    },
  },
  data() {
    return {
      selectedValue: "1",
      dropdownOpen: false,
      menuItems: [
        {
          id: nanoid(6),
          name: "removeWidget",
          iconUrl: "cross.svg",
          field: "Remove",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    widgetClass() {
      return ["widget-main-class", this.widget.size];
    },
  },
  watch: {},
  methods: {
    handleValueChange(newValue) {
      this.selectedValue = newValue;
    },
    selectItem(itemName) {
      switch (itemName) {
        case "removeWidget":
          this.$emit("removeWidget", this.widget._id);
          break;
      }
      this.closeMenu();
    },
    toggleMenu() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeMenu() {
      this.dropdownOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-filters {
  display: flex;
  gap: 16px;
}

.widget-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 8px;
  z-index: 99;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  background: white;
  border-radius: 8px;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
  .menu-item {
    display: flex;
    gap: 4px;
    padding: 8px;
    align-items: center;
    &:hover {
      background-color: #f4f5f7;
    }
    &:only-child {
      border-radius: inherit;
    }
  }
}

.widget-button {
  cursor: pointer;
}

.widget-filter {
  margin-bottom: 12px;
  height: 32px !important;
}

.widgets-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.widget-content {
  flex: 1;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-filter {
  width: 130px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
}
.widget-name {
  font-size: 14px;
  font-weight: 500;
  font-family: Geomanist;
}

.widget-header {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  height: 32px;
  gap: 8px;
}

.widget-header-wrapper {
  display: flex;
  flex-direction: column;
  display: relative;
  padding: 0px 20px;
}

.widget-btn {
  width: 26px;
  height: 26px;
  padding: 4px;
}

.add-widget-container {
  display: flex;
  gap: 8px;
}

.add-widget-button {
  display: flex;
  align-items: center;
}

.widget-buttons {
  display: flex;
  gap: 8px;
}

.widget-main-class {
  background-color: white;
  border-radius: 8px;
  padding: 20px 0px 0 0px;
  border: 1px solid #e6e8ec;
}
</style>
