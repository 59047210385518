var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widgets-wrapper",
    class: _vm.widgetClass
  }, [_c('div', {
    staticClass: "widget-header-wrapper"
  }, [_c('div', {
    staticClass: "widget-header"
  }, [_c('div', {
    staticClass: "widget-name"
  }, [_vm._v(_vm._s(this.$t(_vm.widget.name)))]), _c('div', {
    staticClass: "widget-buttons"
  }, [_c('div', {
    staticClass: "widget-filter"
  }, [_vm._t("widget-filter")], 2), _c('div', {
    staticClass: "widget-enlarge widget-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('enlargeWidget', $event, _vm.widget);
      }
    }
  }, [_c('img', {
    staticClass: "widget-btn",
    attrs: {
      "src": require('@/assets/images/expand_grey.svg'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "widget-options widget-button",
    on: {
      "click": _vm.toggleMenu
    }
  }, [_c('img', {
    staticClass: "widget-btn",
    attrs: {
      "src": require('@/assets/images/dot_menu_grey.svg'),
      "alt": ""
    }
  }), _vm.dropdownOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    staticClass: "widget-menu"
  }, _vm._l(_vm.menuItems, function (item, index) {
    return _c('div', {
      key: 'menu-item-' + index,
      staticClass: "menu-item",
      on: {
        "click": function () {
          return _vm.selectItem(item.name);
        }
      }
    }, [_c('img', {
      staticClass: "img16",
      attrs: {
        "src": require(`../../assets/images/${item.iconUrl}`),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(_vm.$t(item.field)) + " ")])]);
  }), 0) : _vm._e()])])]), _c('div', {
    staticClass: "widget-filters"
  }, [_vm._t("widget-filters")], 2)]), _c('div', {
    staticClass: "widget-content"
  }, [_vm._t("widget-content")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }