<template>
  <div class="overlay">
    <vue-draggable-resizable
      :z="10"
      :resizable="false"
      drag-handle=".drag-area"
      class="widget-container"
      @dragstop="onDragStop"
      v-click-outside="closeModal"
    >
      <div class="add-widget">
        <div class="widget-header drag-area">
          <div>
            <img src="@/assets/images/menu.svg" alt="" />
          </div>
          <div class="close-button">
            <img
              src="@/assets/images/close_grey.svg"
              alt=""
              @click="closeModal"
            />
          </div>
        </div>
        <div class="add-widget-title" style="color: black">
          <h5>{{ $t("AddWidgets") }}</h5>
        </div>
        <div class="modal-content">
          <div
            class="widget-item"
            v-for="widget in widgets"
            :key="widget.id"
            :class="{ selected: isSelectedWidget(widget) }"
            @click="selectWidget(widget)"
          >
            {{ $t(widget.name) }}
          </div>
        </div>
        <div class="add-widget-footer">
          <span class="modal-buttons">
            <button class="transparent-button" @click="closeModal">
              {{ $t("Cancel") }}
            </button>
            <button
              class="transparent-button add-widget-btn"
              @click.prevent="addWidget"
            >
              <span>
                {{ $t("Add") }}
              </span>
            </button>
          </span>
        </div>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import nanoid from "nanoid";
import { mapGetters } from "vuex";

export default {
  name: "AddWidgetModal",
  props: {},
  components: {
    VueDraggableResizable,
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      confirmModalOpen: false,
      widgets: [
        {
          _id: nanoid(8),
          name: "Birthdays",
          component: "BirthdaysWidget",
          size: { width: 2, height: 2 },
          filters: ["users"],
        },
        // {
        //   _id: nanoid(8),
        //   name: "Statistics",
        //   component: "StatisticsWidget",
        //   size: { width: 2, height: 2 },
        //   filters: [""],
        // },
        {
          _id: nanoid(8),
          name: "ContactsAdded",
          component: "ContactsWidget",
          size: { width: 2, height: 2 },
          filters: ["users", "period"],
        },
        {
          _id: nanoid(8),
          name: "TurnoverTop",
          component: "TurnoverWidget",
          size: { width: 2, height: 2 },
          filters: ["users", "period"],
        },
      ],
      selectedWidgets: [],
    };
  },
  created() {},
  mounted() {
    this.checkScorecardAccess();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    closeModal() {
      this.$emit("closeWidgetModal");
    },
    isSelectedWidget(widget) {
      return this.selectedWidgets.includes(widget);
    },
    selectWidget(widget) {
      const index = this.selectedWidgets.findIndex(
        (selectedWidget) => selectedWidget.name === widget.name
      );
      if (index > -1) {
        this.selectedWidgets.splice(index, 1);
      } else {
        this.selectedWidgets.push(widget);
      }
    },
    addWidget() {
      this.$emit("addWidgets", this.selectedWidgets);
      this.selectedWidgets = [];
    },
    checkScorecardAccess() {
      const enabledCompanyIds = [
        "5p1EOo4yqjeiYCN3R16w",
        "yAuL6x3yKIacfejp0MYl"
      ];
      if (enabledCompanyIds.includes(this.user.access.company._id)) {
        const scorecardWidget = {
          _id: nanoid(),
          name: "ScorecardTop",
          component: "ScorecardWidget",
          size: { width: 2, height: 2 },
          filters: ["users", "period"],
        };
        this.widgets.push(scorecardWidget);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  width: 100vw;
  height: 100vh;
  background-color: #0000004f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.add-widget-footer {
  padding: 24px;
  border-top: 1px solid #e6e8ec;
  display: flex;
  justify-content: flex-end;
}

.widget-types {
  display: flex;
}

.modal-buttons {
  display: flex;
  gap: 8px;
}

.add-widget-btn {
  background: black !important;
  color: white;
}

.add-widget-title {
  padding: 12px 24px 0 24px;
}

.widget-item {
  border: 3px solid #f4f5f7;
  display: flex;
  border-radius: 8px;
  padding: 12px;
  white-space: nowrap;
  background-color: #f4f5f7;
  font-weight: 500;
  font-size: 14px;
  font-family: Geomanist;
  cursor: pointer;
  color: #939597;
}

.widget-item.selected {
  border: 3px solid #ff5c01 !important;
  color: black;
}

.widget-container {
  position: fixed;
  left: 34%;
  top: 26% !important;
  padding-bottom: 20px;
  padding-right: 40px;
  width: 100%;
}

.add-widget {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  position: fixed;
  background: white;
  border-radius: 8px;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.close-button {
  cursor: pointer;
  padding: 2px 16px 0 0;
  margin: auto 0;
}

.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 100%;
  background-color: #f4f5f7;
  border-radius: 8px 8px 0 0;
}

.dropdown-row {
  display: flex;
  padding: 6px 24px;
}

.modal-content {
  height: 100%;
  padding: 18px 24px;
  display: flex;
  gap: 8px;
}

.content-title {
  font-size: 12px;
  color: #75787a;
}

::v-deep .v-text-field .v-input__control {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-input__slot {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 16px !important;
}

::v-deep .v-icon.v-icon {
  font-size: 24px !important;
}
</style>
