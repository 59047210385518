var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-wrapper"
  }, [_c('div', {
    staticClass: "users-contacts-count"
  }, [_c('div', {
    staticClass: "users-contacts-count"
  }, _vm._l(_vm.filteredUsersContacts, function (user) {
    return _c('div', {
      key: user._id,
      staticClass: "user-contacts-field",
      class: {
        first: user.ranking === 1
      }
    }, [_c('div', {
      staticClass: "user-contact-left"
    }, [_c('div', {
      staticClass: "user-ranking-bg",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.ranking ? user.ranking : 0) + " ")]), _c('div', {
      staticClass: "user-credentials"
    }, [_c('div', {
      staticClass: "user-name"
    }, [_vm._v(" " + _vm._s(user.name) + " ")]), _c('div', {
      staticClass: "users-detail",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(_vm._s(user.defaultGroup))])])]), _c('div', {
      staticClass: "users-contact-right",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.count) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "user-contact-count"
  }, [_c('div', {
    staticClass: "user-contacts-field"
  }, [_c('div', {
    staticClass: "user-contact-left"
  }, [_c('div', {
    staticClass: "user-ranking-bg"
  }, [_vm._v(_vm._s(_vm.userClientCount.ranking))]), _c('div', {
    staticClass: "user-credentials"
  }, [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(" " + _vm._s(_vm.userClientCount.name) + " (" + _vm._s(_vm.$t("You")) + ") ")]), _c('div', {
    staticClass: "user-detail"
  }, [_vm._v(_vm._s(_vm.userClientCount.defaultGroup))])])]), _c('div', {
    staticClass: "user-contact-right"
  }, [_vm._v(" " + _vm._s(_vm.userClientCount.count) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }