var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("Dashboard")))])]), _c('HeaderToolbar', [_c('v-row', [_c('div', {
    staticClass: "header-buttons"
  }, [_c('button', {
    staticClass: "outlined-small-button",
    attrs: {
      "name": "add-widget-button"
    },
    on: {
      "click": _vm.addWidget
    }
  }, [_c('img', {
    staticClass: "add-widget-img",
    attrs: {
      "src": require('@/assets/images/plusSign.svg'),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-widget-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddWidget")) + " ")])]), _c('div', [_c('DashboardSelector', {
    on: {
      "dashboardUpdated": _vm.handleDashboardUpdated
    },
    model: {
      value: _vm.userDashboards,
      callback: function ($$v) {
        _vm.userDashboards = $$v;
      },
      expression: "userDashboards"
    }
  })], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }