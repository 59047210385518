<template>
  <div class="widget-wrapper">
    <div class="users-count">
      <div class="users-count">
        <div
          class="user-field"
          v-for="user in filteredUsersScorecards"
          :key="user._id"
          :class="{ first: user.ranking === 1 }"
        >
          <div class="user-left">
            <div class="user-ranking-bg" :class="{ first: user.ranking === 1 }">
              {{ user.ranking ? user.ranking : 0 }}
            </div>
            <div class="user-credentials">
              <div class="user-name">
                {{ user.name }}
              </div>
              <div class="users-detail" :class="{ first: user.ranking === 1 }">{{ user.defaultGroup }}</div>
            </div>
          </div>
          <div class="users-right" :class="{ first: user.ranking === 1 }">
            {{ user.points > 0 ? formatTotal(user.points) : 0 }}
          </div>
        </div>
      </div>
    </div>
    <div class="user-count">
      <div class="user-field">
        <div class="user-left">
          <div class="user-ranking-bg">
            {{ userScorecardPoints.ranking }}
          </div>
          <div class="user-credentials">
            <div class="user-name">
              {{ userScorecardPoints.name }} ({{ $t("You") }})
            </div>
            <div class="user-detail">{{ userScorecardPoints.defaultGroup }}</div>
          </div>
        </div>
        <div class="user-right">
          {{ userScorecardPoints.points > 0 ? formatTotal(userScorecardPoints.points) : 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ScorecardTop",
  components: {},
  props: {
    widgetId: {
      type: String,
      required: true,
    },
    filteredUsers: {
      type: Array,
      default: undefined,
    },
    selectedPeriod: {},
  },
  watch: {
    selectedPeriod: {
      async handler() {
        await this.fetchScorecardPoints();
      },
      deep: true
    },
    colleagues: {
      handler: "filterScorecardByColleagues",
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      userScorecardPoints: {},
      usersScorecardPoints: [],
      filteredUsersScorecards: []
    };
  },
  async mounted() {
    await this.fetchScorecardPoints();
  },
  computed: {
    ...mapGetters(["user"]),
    colleagues() {
      return this.filteredUsers;
    },
  },
  methods: {
    async fetchScorecardPoints() {
      try {
        const response = await axios.post(
          `/api/scorecard/leaderboard/${this.user._id}`,
          {
            period: this.selectedPeriod?.value,
          }
        );
        if (response && response.data && this.widgetId) {
          let userPoints = response.data.userPoints;
          let allUsersPoints = response.data.allUsersPoints;

          this.userScorecardPoints = userPoints;
          this.usersScorecardPoints = allUsersPoints;
          this.filterScorecardByColleagues();
          this.$emit("contentLoaded", this.widgetId);
        }
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
    },
    filterScorecardByColleagues() {
      if (this.colleagues && this.colleagues.length > 0) {
        this.filteredUsersScorecards = this.usersScorecardPoints.filter((user) =>
          this.colleagues.some((colleague) => colleague._id === user._id)
        );
      } else {
        this.filteredUsersScorecards = [...this.usersScorecardPoints];
      }
      this.filteredUsersScorecards.sort((a, b) => b.points - a.points);
      let currentRank = 1;
      for (let i = 0; i < this.filteredUsersScorecards.length; i++) {
        if (
          i > 0 &&
          this.filteredUsersScorecards[i].points ===
            this.filteredUsersScorecards[i - 1].points
        ) {
          this.filteredUsersScorecards[i].ranking =
            this.filteredUsersScorecards[i - 1].ranking;
        } else if (this.filteredUsersScorecards[i].points === 0) {
          this.filteredUsersScorecards[i].ranking = "-";
        } else {
          this.filteredUsersScorecards[i].ranking = currentRank;
        }
        currentRank++;
      }
      if (this.userScorecardPoints.points > 0) {
        this.userScorecardPoints.ranking = this.calculateUserRanking();
      } else {
        this.userScorecardPoints.ranking = "-";
      }
    },
    calculateUserRanking() {
      const userInFiltered = this.filteredUsersScorecards.some(
        (user) => user._id === this.user._id
      );
      if (!userInFiltered) {
        return "-";
      }
      let rank = 1;
      let userFound = false;
      for (const user of this.filteredUsersScorecards) {
        if (user.points === 0) {
          continue;
        }
        if (user._id === this.user._id) {
          userFound = true;
          break;
        }
        if (!userFound && user.points > this.userScorecardPoints.points) {
          rank++;
        }
      }
      return userFound
        ? this.filteredUsersScorecards.find((user) => user._id === this.user._id)
            .ranking
        : rank;
    },
    formatTotal(number) {
      const decimalsArray = number.toString().split(".");
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        number = parseFloat(number.toFixed(2));
      }
      return number;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-count {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #e6e8ec;
}

.user-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(244, 245, 247);
}

.user-field.first {
  background-color: rgba(255, 240, 240, 1);
}

.user-left {
  display: flex;
  gap: 8px;
}

.user-right,
.users-right {
  font-family: Geomanist;
  font-size: 18px;
  font-weight: 700;
}

.users-right.first {
  color: rgba(255, 92, 1, 1) !important;
}

.users-count {
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.widget-wrapper {
  display: flex;
  flex-direction: column;
  height: 366px;
}

.user-detail,
.users-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.users-detail.first {
  color: rgba(255, 92, 1, 1) !important;
}

.user-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.user-ranking-bg {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: rgb(230, 232, 236);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 4px 0 0 0;
  font-weight: 500;
  color: #939597;
  font-family: Geomanist;
}

.user-ranking-bg.first {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}
</style>
