<template>
  <div
    v-if="loaded"
    :style="customStyle"
    v-click-outside="closeDropdown"
    v-bind:class="{
      dropdown: !disabled,
      'dropdown dropdown-disabled': disabled,
    }"
  >
    <div
      @keydown="searchItem"
      @click="openDropdown"
      :style="customStyleButton"
      v-bind:class="{
        'dropbtn dropbtn-hover': !disabled,
        dropbtn: disabled,
      }"
      class="dropbtn"
    >
      <div class="label-and-item-wrapper">
        <div v-if="placeholderText" class="field-label">
          <p
            class="field-label-text"
            :style="!selectedItem ? 'font-size: 16px' : null"
          >
            {{ placeholderText }}
          </p>
        </div>
        <p
          v-bind:class="{
            'dropbtn-text regular-size-text default-item': regularSize,
            'dropbtn-text small-size-text default-item': !regularSize,
          }"
          v-if="defaultItem && defaultItem === selectedItem"
        >
          {{ defaultItem.name }}
        </p>
        <p
          v-bind:class="{
            'dropbtn-text regular-size-text': regularSize,
            'dropbtn-text small-size-text': !regularSize,
          }"
          v-else-if="selectedItem"
        >
          {{ selectedItem.name }}
        </p>
        <!--        <p
          :data-placeholder="placeholderText"
          v-bind:class="{
            'dropdown-placeholder regular-size-text': regularSize,
            'dropdown-placeholder small-size-text': !regularSize,
          }"
          v-else
        ></p>-->
      </div>
      <img
        v-bind:class="{
          'dropbtn-arrow': !disabled,
          'dropbtn-arrow arrow-disabled': disabled,
        }"
        src="../../assets/images/chevron-down.svg"
        alt=""
      />
    </div>

    <div
      :id="dropdownId"
      style="display: none"
      class="dropdown-content dropdown-single"
    >
      <div v-if="searchBox" class="search-wrapper">
        <InputSmall
          v-if="dropdownOpen"
          style="width: 100%"
          :placeholder="$t('Search')"
          :type="'text'"
          :auto-focus="true"
          v-model="search"
        >
        </InputSmall>
      </div>
      <div
        v-for="(item, index) in dropdownItems"
        :key="item._id"
        v-bind:class="{
          'dropdown-item-wrapper': !item.inFocus,
          'dropdown-item wrapper dropdown-item-focused': item.inFocus,
        }"
        @click="selectItem(item)"
        :id="dropdownId + '-dropdown-item-' + index"
      >
        <div class="checkmark-wrapper">
          <img
            v-if="selectedItem && item[valueKey] === selectedItem[valueKey]"
            src="../../assets/images/checkmark.svg"
            style="
              filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg)
                brightness(96%) contrast(104%) !important;
            "
            alt=""
          />
        </div>
        <p
          v-bind:class="{
            'not-selected':
              (selectedItem && item[valueKey] !== selectedItem[valueKey]) ||
              !selectedItem,
            selected: selectedItem && item[valueKey] === selectedItem[valueKey],
            'regular-size-text': regularSize,
            'small-size-text': !regularSize,
          }"
        >
          {{ item[displayKey] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";
// eslint-disable-next-line no-unused-vars
import InputSmall from "@/components/common/InputSmall";
import _ from "lodash";
export default {
  name: "Dropdown",
  props: [
    "items",
    "displayKey",
    "defaultItem",
    "valueKey",
    "placeholderText",
    "disabled",
    "updater",
    "customStyle",
    "customStyleButton",
    "regularSize",
    "selected",
    "searchBox",
  ],
  components: { InputSmall },
  data: () => ({
    dropdownId: null,
    selectedItem: null,
    dropdownItems: null,
    dropdownItemsCopy: null,
    loaded: false,
    search: "",
    dropdownOpen: false,
  }),
  created() {
    this.dropdownId = nanoid(10);
    this.dropdownItems = [...this.items];
    this.dropdownItemsCopy = _.cloneDeep(this.dropdownItems);
    this.selectedItem = this.defaultItem;
    if (this.selected) {
      this.selectedItem = this.selected;
    }
    this.loaded = true;
  },
  mounted() {},
  beforeDestroy() {
    window.onclick = null;
  },
  methods: {
    searchItem(search) {
      console.log(search);
    },
    selectItem(item) {
      this.$emit("itemSelected", item);
      this.selectedItem = item;
      this.dropdownOpen = false;
      this.closeDropdown();
    },
    openDropdown() {
      let dropdownContent = document.getElementById(this.dropdownId);
      let dropbtn = this.$el.querySelector(".dropbtn");

      this.dropdownOpen = !this.dropdownOpen;

      if (this.dropdownOpen) {
        dropdownContent.style.display = "flex";
        this.$nextTick(() => {
          this.adjustButtonWidth();
          dropdownContent.style.display = "";
        });
      } else {
        dropdownContent.style.display = "none";
        dropbtn.style.minWidth = "";
      }
    },
    adjustButtonWidth() {
      let dropdownContent = document.getElementById(this.dropdownId);
      let dropbtn = this.$el.querySelector(".dropbtn");
      if (dropdownContent.offsetWidth > dropbtn.offsetWidth) {
        dropbtn.style.minWidth = `${dropdownContent.offsetWidth}px`;
      }
    },
    closeDropdown() {
      if (document.getElementById(this.dropdownId)) {
        document.getElementById(this.dropdownId).style.display = "none";
        this.search = "";
        this.dropdownOpen = false;
        let dropbtn = this.$el.querySelector(".dropbtn");
        dropbtn.style.minWidth = "";
      }
    },
  },
  watch: {
    selected(val) {
      this.selectedItem = val;
    },
    search(val) {
      if (val === "" || !val) {
        this.dropdownItems = this.dropdownItemsCopy;
        return;
      }
      let matchingItems = [];
      let searchRegex = new RegExp(val.toLowerCase(), "g");
      this.dropdownItemsCopy.forEach((item) => {
        if (item[this.displayKey].toLowerCase().match(searchRegex)) {
          matchingItems.push(item);
        }
      });
      this.dropdownItems = matchingItems;
    },
    /*updater() {
      console.log("DROPDOWN UUENDATUD!!", this.items);
      this.dropdownItems = [...this.items];
      this.selectedItem = this.dropdownItems[0];
      this.dropdownId = nanoid(10);
      /!*this.dropdownId = nanoid(10);
      this.dropdownItems = [...this.items];
      this.selectedItem = this.dropdownItems[0];*!/
    },*/
  },
};
</script>

<style lang="scss" scoped>
.dropbtn {
  //background-color: white;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  /* identical to box height, or 133% */
  padding: 8px 12px 8px 12px;
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  min-width: 120px;
  flex-direction: row;
  /* black */
  cursor: default;
  color: #000000;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: space-between;
  img {
    margin-left: 8px;
  }
}
.search-wrapper {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid #ff5c01;
}
.dropbtn-hover {
  &:hover,
  &:focus {
    cursor: pointer;

    //background-color: #f4f5f7;
  }
}
.regular-size-text {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}
.small-size-text {
  font-size: 12px;
  line-height: 16px;
  //color: white;
}
/*.dropbtn:hover,
.dropbtn:focus {
  background-color: #f4f5f7;
}*/

.dropdown {
  position: relative;
  display: inline-block;
  //z-index: 1;
}

.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &:hover {
    background-color: #f4f5f7;
  }
}
.dropdown-item-focused {
  background-color: #f4f5f7 !important;
}
.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  min-width: 160px;
  max-height: 400px;
  overflow: scroll;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  margin-top: 8px;
  border-radius: 8px;
  p {
    color: black;
    text-decoration: none;
    display: block;
  }
}
.not-selected,
.selected {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.selected {
  font-weight: 600;
}
.checkmark-wrapper {
  min-width: 16px;
  height: 16px;
}
.dropdown-placeholder:empty:before {
  content: attr(data-placeholder);
  color: gray;
}
.default-item {
  color: #75787a;
}
.arrow-disabled {
  filter: invert(99%) sepia(1%) saturate(51%) hue-rotate(140deg)
    brightness(115%) contrast(88%);
}
.dropbtn-disabled {
  p {
    color: #f0f0f0 !important;
  }
}
.dropdown-disabled {
  opacity: 0.5;
}
.label-and-item-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
</style>
