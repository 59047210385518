<template>
  <div class="content">
    <GridContent
      :added-widgets="addedWidgets"
      :user-dashboard="userDashboard"
      @addWidgets="showAddWidgetModal"
    />
    <AddWidgetModal
      v-if="isWidgetModal"
      @closeWidgetModal="handleCloseModal"
      @addWidgets="handleAddWidgets"
    />
  </div>
</template>

<script>
import GridContent from "@/components/Dashboard/GridContent.vue";
import AddWidgetModal from "./AddWidgetModal.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "DashboardContent",
  props: {
    widgetModalOpened: {
      type: Boolean,
    },
    userDashboard: {
      type: Object,
    },
  },
  components: {
    GridContent,
    AddWidgetModal,
  },
  data() {
    return {
      isWidgetModal: false,
      addedWidgets: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    widgetModalOpened(newVal) {
      this.isWidgetModal = newVal;
    },
  },
  mounted() {},
  methods: {
    async deleteTask(taskId) {
      let msToken = null;
      let nylasAccessToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;
        }
      }
      if (this.user.nylas) {
        const accessToken = await this.getNylasAccessToken();
        if (accessToken) {
          nylasAccessToken = accessToken;
        }
      }
      const res = await axios.post(`/api/task/delete/${taskId}`, {
        accessToken: msToken,
        nylasAccessToken
      });

      if (res.status === 200) {
        this.tasks = this.tasks.filter((task) => task._id !== taskId);
        this.taskUpdater++;
        this.toastSuccess(this.$t("Deleted"));
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
    ...mapActions(["addWidget"]),
    handleCloseModal() {
      this.isWidgetModal = false;
      this.$emit("closeModal");
    },
    handleAddWidgets(selectedWidgets) {
      const activeDashboard = this.userDashboard;
      if (activeDashboard && selectedWidgets.length > 0) {
        const payload = {
          widgets: selectedWidgets.map(({ name, component, size, filters }) => ({
            name,
            component,
            size,
            filters
          })),
        };
        axios
          .post(
            `/api/dashboard/add-dashboard-widget/${activeDashboard._id}`,
            payload
          )
          .then((response) => {
            const addedWidgetIds = response.data.addedWidgetIds;
            const updatedWidgets = selectedWidgets.map((widget, index) => ({
              ...widget,
              _id: addedWidgetIds[index],
            }));
            this.addedWidgets = [...updatedWidgets];
            if (this.addedWidgets.length < 2) {
              this.toastSuccess(this.$t("WidgetAdded"));
            } else {
              this.toastSuccess(this.$t("WidgetsAdded"));
            }

          })
          .catch((error) => {
            console.error("Error adding widgets to dashboard:", error);
          });
      } else {
        console.error("No active dashboard or no widgets selected");
      }
      this.handleCloseModal();
    },
    showAddWidgetModal() {
      this.isWidgetModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  margin-top: 12px;
}
</style>
