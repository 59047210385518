<template>
  <vue-draggable-resizable
    :z="3"
    style="position: fixed"
    :resizable="false"
    drag-handle=".drag-area"
    id="widget-overlay"
    class="widget-overlay"
    @dragstop="onDragStop"
  >
    <div id="widget" class="content" v-click-outside="closePanel">
      <div class="drag-area">
        <img src="@/assets/images/menu.svg" alt="" />
      </div>
      <div class="dashboard-panel-content">
        <div class="add-dashboard-title">
          <h5>{{ $t("AddDashboard") }}</h5>
        </div>
        <div>
          <input
            class="dashboard-title"
            v-model="dashboard.name"
            :placeholder="$t('AddTitle')"
          />
        </div>
        <div
          v-if="fieldError"
          class="content error-message"
        >
          {{
            fieldErrorMessage
              ? fieldErrorMessage
              : $t("FormErrors.FieldRequired")
          }}
        </div>
      </div>
      <div class="dashboard-panel-footer">
        <span class="modal-buttons">
          <button class="transparent-button" @click="cancelModal">
            {{ $t("Cancel") }}
          </button>
          <button class="transparent-button save-button" @click="saveDashboard">
            <span>
              {{ $t("Add") }}
            </span>
          </button>
        </span>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import axios from "axios";

export default {
  name: "AddDashboardModal",
  components: {
    VueDraggableResizable,
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      dashboard: {
        name: "",
      },
      fieldError: false,
      fieldErrorMessage: "",
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["user"]),
    isToggleDisabled() {
      return this.dashboard.isDefault;
    },
  },
  methods: {
    ...mapActions(["setDashboardView"]),
    cancelModal() {
      this.$emit("cancel");
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    toggleDefault() {
      this.dashboard.isDefault = !this.dashboard.isDefault;
    },
    async saveDashboard() {
      try {
        if (!this.dashboard.name.trim()) {
          this.fieldError = true;
          this.fieldErrorMessage = this.$t("FormErrors.FieldRequired");
          return;
        }
        const payload = {
          name: this.dashboard.name,
          userId: this.user._id,
        };
        await axios.post("/api/dashboard/save-dashboard", payload);
        this.toastSuccess(this.$t("DashboardAdded"));
        this.$emit("dashboardCreated");
      } catch (error) {
        console.error("Error saving dashboard:", error);
      }
    },
    getSelectedWidgets() {
      return this.widgets
        .filter((widget) => widget.selected)
        .map((widget) => widget.id);
    },
    toggleWidgetSelection(widget) {
      widget.selected = !widget.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";
.dashboard-widgets {
  display: flex;
  gap: 16px;
  height: 54px;
  align-items: center;
}

.modal-buttons {
  display: flex;
  gap: 8px;
}

.select-name,
.select-dot {
  padding: 8px;
  white-space: nowrap;
  width: 100%;
}

.default-view-disabled {
  cursor: unset !important;
}

.default-view {
  display: flex;
  gap: 12px;
  align-items: center;
  inline-size: fit-content;
  cursor: pointer;
}

.toggle-disabled {
  filter: invert(90%) sepia(13%) saturate(5%) hue-rotate(332deg) brightness(89%)
    contrast(92%);
}

.dashboard-title {
  border-bottom: 2px solid black;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 4px 4px 8px 4px;
}

input {
  border: 0;
  outline: 0;
}

input:focus {
  outline: none !important;
}

.add-dashboard-title {
  padding: 0px 12px 12px 0px;
}

.delete-button {
  color: $red !important;
  border: 1px solid $red !important;
}

.save-button {
  background: black !important;
  color: white;
}

.dashboard-panel-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dashboard-panel-footer {
  padding: 24px;
  border-top: 1px solid $grey500;
  display: flex;
  justify-content: flex-end;
}

.widget-overlay {
  z-index: 130;
  position: fixed;
  display: flex;
}

#widget {
  top: 20%;
  left: 0%;
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.drag-area {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $grey;
  padding: 12px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid $grey500;
  img {
    cursor: pointer;
  }
}

.error-message {
  color: #ff1e24 !important;
  margin: 4px 0 0 0;
}
</style>
