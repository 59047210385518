var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-grid-content"
  }, [!_vm.dashboardLoaded ? _c('div', {
    staticClass: "content-loading"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm.localWidgets && _vm.localWidgets.length < 1 ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoWidgetsFound")) + " ")]), _c('p', {
    staticClass: "add-widget-button",
    on: {
      "click": _vm.addWidgets
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddWidgets")) + " ")])]) : _c('div', {
    staticClass: "widget-grid-container"
  }, [_vm.enlargedWidget ? _c('div', {
    staticClass: "overlay"
  }, [_c('Widget', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEnlargedWidget,
      expression: "closeEnlargedWidget"
    }],
    staticClass: "enlargedWidget",
    style: _vm.activeWidgetOriginalSize,
    attrs: {
      "widget": _vm.enlargedWidget
    },
    on: {
      "removeWidget": _vm.removeWidget
    },
    scopedSlots: _vm._u([{
      key: "widget-filter",
      fn: function () {
        var _vm$enlargedWidget, _vm$enlargedWidget$fi, _vm$enlargedWidget2, _vm$enlargedWidget2$f, _vm$enlargedWidget3, _vm$enlargedWidget3$f, _vm$enlargedWidget4, _vm$enlargedWidget4$f;
        return [(_vm$enlargedWidget = _vm.enlargedWidget) !== null && _vm$enlargedWidget !== void 0 && (_vm$enlargedWidget$fi = _vm$enlargedWidget.filters) !== null && _vm$enlargedWidget$fi !== void 0 && _vm$enlargedWidget$fi.includes('users') && ((_vm$enlargedWidget2 = _vm.enlargedWidget) === null || _vm$enlargedWidget2 === void 0 ? void 0 : (_vm$enlargedWidget2$f = _vm$enlargedWidget2.filters) === null || _vm$enlargedWidget2$f === void 0 ? void 0 : _vm$enlargedWidget2$f.length) === 1 ? _c('div', {
          staticClass: "widget-filter"
        }, [_c('GroupOrUserSelect', {
          attrs: {
            "id": _vm.enlargedWidget._id,
            "allow-only-first-level-data": _vm.allowOnlyFirstLevelData[_vm.enlargedWidget._id]
          },
          on: {
            "change": function (value) {
              return _vm.handleWidgetUsersChange(_vm.enlargedWidget._id, value);
            }
          },
          model: {
            value: _vm.selectedUsersForWidget[_vm.enlargedWidget._id],
            callback: function ($$v) {
              _vm.$set(_vm.selectedUsersForWidget, _vm.enlargedWidget._id, $$v);
            },
            expression: "selectedUsersForWidget[enlargedWidget._id]"
          }
        })], 1) : _vm._e(), (_vm$enlargedWidget3 = _vm.enlargedWidget) !== null && _vm$enlargedWidget3 !== void 0 && (_vm$enlargedWidget3$f = _vm$enlargedWidget3.filters) !== null && _vm$enlargedWidget3$f !== void 0 && _vm$enlargedWidget3$f.includes('period') && ((_vm$enlargedWidget4 = _vm.enlargedWidget) === null || _vm$enlargedWidget4 === void 0 ? void 0 : (_vm$enlargedWidget4$f = _vm$enlargedWidget4.filters) === null || _vm$enlargedWidget4$f === void 0 ? void 0 : _vm$enlargedWidget4$f.length) === 1 ? _c('div', {
          staticClass: "widget-filter"
        }, [_c('Dropdown', {
          attrs: {
            "items": _vm.periods,
            "selected": _vm.selectedPeriodsForWidget[_vm.enlargedWidget._id],
            "value-key": 'name',
            "display-key": 'name',
            "height": '34px',
            "width": '120px',
            "font-size": '12px'
          },
          on: {
            "itemSelected": function (value) {
              return _vm.handleSelectedPeriod(_vm.enlargedWidget._id, value);
            }
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "widget-filters",
      fn: function () {
        var _vm$enlargedWidget5, _vm$enlargedWidget5$f, _vm$enlargedWidget6, _vm$enlargedWidget6$f, _vm$enlargedWidget7, _vm$enlargedWidget7$f, _vm$enlargedWidget8, _vm$enlargedWidget8$f;
        return [(_vm$enlargedWidget5 = _vm.enlargedWidget) !== null && _vm$enlargedWidget5 !== void 0 && (_vm$enlargedWidget5$f = _vm$enlargedWidget5.filters) !== null && _vm$enlargedWidget5$f !== void 0 && _vm$enlargedWidget5$f.includes('users') && ((_vm$enlargedWidget6 = _vm.enlargedWidget) === null || _vm$enlargedWidget6 === void 0 ? void 0 : (_vm$enlargedWidget6$f = _vm$enlargedWidget6.filters) === null || _vm$enlargedWidget6$f === void 0 ? void 0 : _vm$enlargedWidget6$f.length) > 1 ? _c('div', {
          staticClass: "widget-filter"
        }, [_c('GroupOrUserSelect', {
          attrs: {
            "id": _vm.enlargedWidget._id,
            "allow-only-first-level-data": _vm.allowOnlyFirstLevelData[_vm.enlargedWidget._id]
          },
          on: {
            "change": function (value) {
              return _vm.handleWidgetUsersChange(_vm.enlargedWidget._id, value);
            }
          },
          model: {
            value: _vm.selectedUsersForWidget[_vm.enlargedWidget._id],
            callback: function ($$v) {
              _vm.$set(_vm.selectedUsersForWidget, _vm.enlargedWidget._id, $$v);
            },
            expression: "selectedUsersForWidget[enlargedWidget._id]"
          }
        })], 1) : _vm._e(), (_vm$enlargedWidget7 = _vm.enlargedWidget) !== null && _vm$enlargedWidget7 !== void 0 && (_vm$enlargedWidget7$f = _vm$enlargedWidget7.filters) !== null && _vm$enlargedWidget7$f !== void 0 && _vm$enlargedWidget7$f.includes('period') && ((_vm$enlargedWidget8 = _vm.enlargedWidget) === null || _vm$enlargedWidget8 === void 0 ? void 0 : (_vm$enlargedWidget8$f = _vm$enlargedWidget8.filters) === null || _vm$enlargedWidget8$f === void 0 ? void 0 : _vm$enlargedWidget8$f.length) > 1 ? _c('div', {
          staticClass: "widget-filter"
        }, [_c('Dropdown', {
          attrs: {
            "items": _vm.periods,
            "selected": _vm.selectedPeriodsForWidget[_vm.enlargedWidget._id],
            "default-item": _vm.selectedDefaultPeriod,
            "value-key": 'name',
            "display-key": 'name',
            "height": '34px',
            "width": '120px',
            "font-size": '12px'
          },
          on: {
            "itemSelected": function (value) {
              return _vm.handleSelectedPeriod(_vm.enlargedWidget._id, value);
            }
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "widget-content",
      fn: function () {
        return [_c(_vm.enlargedWidget.component, {
          tag: "component",
          attrs: {
            "widget-id": _vm.enlargedWidget._id,
            "filtered-users": _vm.filteredUsersForWidget[_vm.enlargedWidget._id],
            "group-or-user": _vm.groupOrUser[_vm.enlargedWidget._id],
            "selected-period": _vm.selectedPeriodsForWidget[_vm.enlargedWidget._id]
          },
          on: {
            "updateFilterLevel": function ($event) {
              return _vm.handleFilterLevel(_vm.enlargedWidget._id, $event);
            },
            "clearGroup": _vm.handleClearGroup,
            "contentLoaded": _vm.contentLoaded
          }
        }), _c('div', {
          staticClass: "loader-container"
        }, [!_vm.enlargedWidget.loaded ? _c('div', {
          staticClass: "loader-center"
        }, [_c('div', {
          staticClass: "loader"
        })]) : _vm._e()])];
      },
      proxy: true
    }], null, false, 3655772580)
  })], 1) : _vm._e(), _c('draggable', {
    staticClass: "drag-area",
    attrs: {
      "list": _vm.localWidgets,
      "animation": 200
    },
    on: {
      "end": _vm.onDragEnd
    }
  }, _vm._l(_vm.localWidgets, function (widget) {
    return _c('div', {
      key: widget._id,
      style: {
        gridColumn: 'span ' + widget.size.width,
        gridRow: 'span ' + widget.size.height
      }
    }, [_c('Widget', {
      attrs: {
        "widget": widget
      },
      on: {
        "removeWidget": _vm.removeWidget,
        "enlargeWidget": _vm.enlargeWidget
      },
      scopedSlots: _vm._u([{
        key: "widget-filter",
        fn: function () {
          var _widget$filters, _widget$filters2, _widget$filters3, _widget$filters4;
          return [widget !== null && widget !== void 0 && (_widget$filters = widget.filters) !== null && _widget$filters !== void 0 && _widget$filters.includes('users') && (widget === null || widget === void 0 ? void 0 : (_widget$filters2 = widget.filters) === null || _widget$filters2 === void 0 ? void 0 : _widget$filters2.length) === 1 ? _c('div', {
            staticClass: "widget-filter"
          }, [_c('GroupOrUserSelect', {
            attrs: {
              "id": widget._id,
              "allow-only-first-level-data": _vm.allowOnlyFirstLevelData[widget._id]
            },
            on: {
              "change": function (value) {
                return _vm.handleWidgetUsersChange(widget._id, value);
              }
            },
            model: {
              value: _vm.selectedUsersForWidget[widget._id],
              callback: function ($$v) {
                _vm.$set(_vm.selectedUsersForWidget, widget._id, $$v);
              },
              expression: "selectedUsersForWidget[widget._id]"
            }
          })], 1) : _vm._e(), widget !== null && widget !== void 0 && (_widget$filters3 = widget.filters) !== null && _widget$filters3 !== void 0 && _widget$filters3.includes('period') && (widget === null || widget === void 0 ? void 0 : (_widget$filters4 = widget.filters) === null || _widget$filters4 === void 0 ? void 0 : _widget$filters4.length) === 1 ? _c('div', {
            staticClass: "widget-filter"
          }, [_c('Dropdown', {
            attrs: {
              "items": _vm.periods,
              "selected": _vm.selectedPeriodsForWidget[widget._id],
              "default-item": _vm.selectedDefaultPeriod,
              "value-key": 'name',
              "display-key": 'name',
              "height": '34px',
              "width": '120px',
              "font-size": '12px'
            },
            on: {
              "itemSelected": function (value) {
                return _vm.handleSelectedPeriod(widget._id, value);
              }
            }
          })], 1) : _vm._e()];
        },
        proxy: true
      }, {
        key: "widget-filters",
        fn: function () {
          var _widget$filters5, _widget$filters6, _widget$filters7, _widget$filters8;
          return [widget !== null && widget !== void 0 && (_widget$filters5 = widget.filters) !== null && _widget$filters5 !== void 0 && _widget$filters5.includes('users') && (widget === null || widget === void 0 ? void 0 : (_widget$filters6 = widget.filters) === null || _widget$filters6 === void 0 ? void 0 : _widget$filters6.length) > 1 ? _c('div', {
            staticClass: "widget-filter"
          }, [_c('GroupOrUserSelect', {
            attrs: {
              "id": widget._id,
              "allow-only-first-level-data": _vm.allowOnlyFirstLevelData[widget._id]
            },
            on: {
              "change": function (value) {
                return _vm.handleWidgetUsersChange(widget._id, value);
              }
            },
            model: {
              value: _vm.selectedUsersForWidget[widget._id],
              callback: function ($$v) {
                _vm.$set(_vm.selectedUsersForWidget, widget._id, $$v);
              },
              expression: "selectedUsersForWidget[widget._id]"
            }
          })], 1) : _vm._e(), widget !== null && widget !== void 0 && (_widget$filters7 = widget.filters) !== null && _widget$filters7 !== void 0 && _widget$filters7.includes('period') && (widget === null || widget === void 0 ? void 0 : (_widget$filters8 = widget.filters) === null || _widget$filters8 === void 0 ? void 0 : _widget$filters8.length) > 1 ? _c('div', {
            staticClass: "widget-filter"
          }, [_c('Dropdown', {
            attrs: {
              "items": _vm.periods,
              "selected": _vm.selectedPeriodsForWidget[widget._id],
              "default-item": _vm.selectedDefaultPeriod,
              "value-key": 'name',
              "display-key": 'name',
              "height": '34px',
              "width": '120px',
              "font-size": '12px'
            },
            on: {
              "itemSelected": function (value) {
                return _vm.handleSelectedPeriod(widget._id, value);
              }
            }
          })], 1) : _vm._e()];
        },
        proxy: true
      }, {
        key: "widget-content",
        fn: function () {
          return [_c(widget.component, {
            tag: "component",
            attrs: {
              "widget-id": widget._id,
              "filtered-users": _vm.filteredUsersForWidget[widget._id],
              "group-or-user": _vm.groupOrUser[widget._id],
              "selected-period": _vm.selectedPeriodsForWidget[widget._id]
            },
            on: {
              "updateFilterLevel": function ($event) {
                return _vm.handleFilterLevel(widget._id, $event);
              },
              "clearGroup": _vm.handleClearGroup,
              "contentLoaded": _vm.contentLoaded
            }
          }), _c('div', {
            staticClass: "loader-container"
          }, [!widget.loaded ? _c('div', {
            staticClass: "loader-center"
          }, [_c('div', {
            staticClass: "loader"
          })]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 0)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }