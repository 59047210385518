<template>
  <div class="header">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("Dashboard") }}</h3>
    </v-row>
    <HeaderToolbar>
      <v-row>
        <div class="header-buttons">
          <button
            name="add-widget-button"
            class="outlined-small-button"
            @click="addWidget"
          >
            <img
              :src="require('@/assets/images/plusSign.svg')"
              class="add-widget-img"
              alt=""
            />
            <span class="add-widget-text">
              {{ $t("AddWidget") }}
            </span>
          </button>
          <div>
            <DashboardSelector
              v-model="userDashboards"
              @dashboardUpdated="handleDashboardUpdated"
            />
          </div>
        </div>
      </v-row>
    </HeaderToolbar>
  </div>
</template>

<script>
import HeaderToolbar from "@/components/common/HeaderToolbar.vue";
import DashboardSelector from "./DashboardSelector.vue";

export default {
  name: "DashboardHeader",
  components: {
    HeaderToolbar,
    DashboardSelector,
  },
  props: {
    userDashboards: {
      type: Array,
    },
  },
  data() {
    return {
      
    };
  },
  created() {},
  mounted() {},
  methods: {
    addWidget() {
      this.$emit("openAddWidget");
    },
    handleDashboardUpdated() {
      this.$emit("dashboardUpdated");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  padding-top: 0px;
}

.add-widget-text {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
    brightness(103%) contrast(102%);
}

.header-buttons {
  display: flex;
  gap: 8px;
}

.outlined-small-button {
  background-color: black !important;
}

.add-widget-img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
    brightness(103%) contrast(102%);
}

.title-row {
  height: 32px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.header-title {
  font-size: 32px;
  line-height: 32px;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
</style>
