var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay"
  }, [_c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeModal,
      expression: "closeModal"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 10,
      "resizable": false,
      "drag-handle": ".drag-area"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    staticClass: "add-widget"
  }, [_c('div', {
    staticClass: "widget-header drag-area"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "close-button"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/close_grey.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closeModal
    }
  })])]), _c('div', {
    staticClass: "add-widget-title",
    staticStyle: {
      "color": "black"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("AddWidgets")))])]), _c('div', {
    staticClass: "modal-content"
  }, _vm._l(_vm.widgets, function (widget) {
    return _c('div', {
      key: widget.id,
      staticClass: "widget-item",
      class: {
        selected: _vm.isSelectedWidget(widget)
      },
      on: {
        "click": function ($event) {
          return _vm.selectWidget(widget);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(widget.name)) + " ")]);
  }), 0), _c('div', {
    staticClass: "add-widget-footer"
  }, [_c('span', {
    staticClass: "modal-buttons"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button add-widget-btn",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addWidget.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }