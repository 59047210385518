var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-wrapper"
  }, [_c('div', {
    staticClass: "users-count"
  }, [_c('div', {
    staticClass: "users-count"
  }, _vm._l(_vm.filteredUsersScorecards, function (user) {
    return _c('div', {
      key: user._id,
      staticClass: "user-field",
      class: {
        first: user.ranking === 1
      }
    }, [_c('div', {
      staticClass: "user-left"
    }, [_c('div', {
      staticClass: "user-ranking-bg",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.ranking ? user.ranking : 0) + " ")]), _c('div', {
      staticClass: "user-credentials"
    }, [_c('div', {
      staticClass: "user-name"
    }, [_vm._v(" " + _vm._s(user.name) + " ")]), _c('div', {
      staticClass: "users-detail",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(_vm._s(user.defaultGroup))])])]), _c('div', {
      staticClass: "users-right",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.points > 0 ? _vm.formatTotal(user.points) : 0) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "user-count"
  }, [_c('div', {
    staticClass: "user-field"
  }, [_c('div', {
    staticClass: "user-left"
  }, [_c('div', {
    staticClass: "user-ranking-bg"
  }, [_vm._v(" " + _vm._s(_vm.userScorecardPoints.ranking) + " ")]), _c('div', {
    staticClass: "user-credentials"
  }, [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(" " + _vm._s(_vm.userScorecardPoints.name) + " (" + _vm._s(_vm.$t("You")) + ") ")]), _c('div', {
    staticClass: "user-detail"
  }, [_vm._v(_vm._s(_vm.userScorecardPoints.defaultGroup))])])]), _c('div', {
    staticClass: "user-right"
  }, [_vm._v(" " + _vm._s(_vm.userScorecardPoints.points > 0 ? _vm.formatTotal(_vm.userScorecardPoints.points) : 0) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }