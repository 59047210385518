var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toolbar-row",
    class: {
      'toolbar-row': _vm.module !== 'propertyDeveloper',
      'toolbar-row left-padding-24': _vm.module === 'propertyDeveloper',
      'toolbar-row no-padding': _vm.module === 'invoices'
    },
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "toolbar-main"
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }