var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    staticClass: "widget-overlay",
    staticStyle: {
      "position": "fixed"
    },
    attrs: {
      "z": 3,
      "resizable": false,
      "drag-handle": ".drag-area",
      "id": "widget-overlay"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "content",
    attrs: {
      "id": "widget"
    }
  }, [_c('div', {
    staticClass: "drag-area"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dashboard-panel-content"
  }, [_c('div', {
    staticClass: "add-dashboard-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("AddDashboard")))])]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.dashboard.name,
      expression: "dashboard.name"
    }],
    staticClass: "dashboard-title",
    attrs: {
      "placeholder": _vm.$t('AddTitle')
    },
    domProps: {
      "value": _vm.dashboard.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.dashboard, "name", $event.target.value);
      }
    }
  })]), _vm.fieldError ? _c('div', {
    staticClass: "content error-message"
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "dashboard-panel-footer"
  }, [_c('span', {
    staticClass: "modal-buttons"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.cancelModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button save-button",
    on: {
      "click": _vm.saveDashboard
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }