<template>
  <div
    class="dashboard-dropdown-wrapper"
    :class="{ dashboardOpened: dropdownOpen }"
    v-click-outside="closeDropdown"
  >
    <div @click="toggleDropdown" class="dashboard-dropdown-button">
      <div class="selected-dashboard-wrapper">
        <img src="@/assets/images/bookmark.svg" alt="" class="img16" />
        <div class="button-small ellipsis-text">
          {{ selectedDashboards }}
        </div>
      </div>
      <img src="@/assets/images/chevron-disabled.svg" alt="" class="img16" />
    </div>
    <div v-if="dropdownOpen" class="dropdown-items-wrapper">
      <div
        v-for="item in value"
        :key="item.id"
        class="dropdown-item"
        @click="selectDashboard(item)"
      >
        <div class="checkmark-wrapper">
          <img
            v-if="item.isDefault"
            src="@/assets/images/checkmark.svg"
            alt=""
            class="img16"
          />
        </div>
        <div class="">{{ item.isPermanent ? $t(item.name) : item.name }}</div>
      </div>
      <div class="dropdown-divider" />
      <div class="dropdown-item" @click="openAddDashboard">
        <img :src="require(`@/assets/images/plus.svg`)" alt="" class="img16" />
        <div class="">
          {{ $t("AddDashboard") }}
        </div>
      </div>
      <div class="dropdown-item" @click="deleteDashboard">
        <img
          :src="require(`@/assets/images/TrashBin.svg`)"
          alt=""
          class="img16"
        />
        <div class="delete-text">
          {{ $t("DeleteDashboard") }}
        </div>
      </div>
    </div>
    <div class="add-dashboard-modal" v-if="isAddDashboard">
      <AddDashboardModal
        @dashboardCreated="handleDashboardCreated"
        @cancel="handleCancel"
      />
    </div>
  </div>
</template>

<script>
import AddDashboardModal from "@/components/Dashboard/AddDashboardModal.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "DashboardSelector",
  components: { AddDashboardModal },
  data: () => ({
    disabled: false,
    dropdownOpen: false,
    dropdownItems: [],
    isAddDashboard: false,
    selectedDashboards: "",
  }),
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.updateSelectedDashboard();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.updateSelectedDashboard();
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    async selectDashboard(dashboard) {
      this.dropdownOpen = false;
      if (dashboard.isDefault) {
        return;
      }
      try {
        await axios.post(
          `/api/dashboard/set-default-dashboard/${this.user._id}/${dashboard._id}`
        );

        this.$emit("dashboardUpdated");
      } catch (error) {
        console.error("Error setting default dashboard:", error);
      }
    },
    handleDashboardCreated() {
      this.isAddDashboard = false;
      this.$emit("dashboardUpdated");
    },
    handleCancel() {
      this.isAddDashboard = false;
    },
    updateSelectedDashboard() {
      const defaultDashboard = this.value.find((d) => d.isDefault);
      this.selectedDashboards = defaultDashboard ? defaultDashboard.isPermanent ? this.$t(defaultDashboard.name) : defaultDashboard.name : "";
    },
    async deleteDashboard() {
      const dashboardToDelete = this.value.find((d) => d.isDefault);

      if (dashboardToDelete && !dashboardToDelete.isPermanent) {
        this.dropdownOpen = false;

        try {
          await axios.post(`/api/dashboard/delete-dashboard/${dashboardToDelete._id}`);

          this.$emit("dashboardUpdated");
          this.toastSuccess(this.$t("DashboardDeleted"));
        } catch (error) {
          console.error("Error deleting dashboard:", error);
        }
      } else {
        this.toastError(this.$t("CannotDeleteMainDashboard"));
      }
    },
    openAddDashboard() {
      this.isAddDashboard = true;
      this.dropdownOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";
.dashboard-dropdown-wrapper {
  min-width: 120px;
  max-width: 180px;
  position: relative;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}

.dashboardOpened {
  width: 180px;
}

.checkmark-wrapper {
  width: 16px;
  height: 16px;
}

.selected-dashboard-wrapper {
  display: flex;
  gap: 8px;
}

.dashboard-dropdown-button {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid $grey500;
  border-radius: 8px;
}

.dropdown-item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  align-items: center;
}

.dropdown-divider {
  height: 1px;
  background: $grey500;
}

.dropdown-items-wrapper {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 120;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  margin: 8px 0 0 0;
  width: 180px;
  transition: width 0.3s;
}

.dashboard-dropdown-wrapper.open .dropdown-items-wrapper {
  width: 100%;
}

.delete-text {
  white-space: nowrap;
}
</style>
