<template>
  <input
    :name="name"
    :id="id + 'input-small'"
    :value="value"
    :type="type"
    :placeholder="placeholder"
    ref="searchInputDropdown"
    @input="$emit('input', $event.target.value)"
    @keyup="$emit('keyUp')"
    @change="$emit('change')"
  />
</template>

<script>
import nanoid from "nanoid";
export default {
  name: "InputSmall",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    width: {
      type: String,
      required: false,
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      id: nanoid(10),
    };
  },
  mounted() {
    if (this.width) {
      document.getElementById(this.id + "input-small").style.width = this.width;
    }
    if (this.autoFocus) {
      this.$nextTick(() => {
        this.$refs.searchInputDropdown.focus();
      });
    }
  },
  computed: {
    name() {
      return this.placeholder.toLowerCase();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
input {
  display: flex;
  width: 120px;
  padding: 8px 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: black;
  text-align: left;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #75787a;
  }
}
</style>
