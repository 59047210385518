<template>
  <div
    style="width: 100%"
    v-bind:class="{
      'toolbar-row': module !== 'propertyDeveloper',
      'toolbar-row left-padding-24': module === 'propertyDeveloper',
      'toolbar-row no-padding': module === 'invoices',
    }"
    class="toolbar-row"
    id="toolbar-main"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderToolbar",
  props: ["module", "topMargin"], //listings, contacts, projects, calendar
  data() {
    return {
      header: null,
      sticky: null,
      content: null,
    };
  },
  created() {},
  mounted() {
    this.header = document.getElementById("toolbar-main");
    this.sticky = window.pageYOffset + this.header.getBoundingClientRect().top;

    this.content = document.getElementById(this.module + "-content");
    if (this.module !== "projects") {
      window.addEventListener("scroll", this.makeToolbarSticky);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.makeToolbarSticky);
  },
  computed: {
    ...mapGetters(["propertyDetailPanel"]),
  },
  methods: {
    makeToolbarSticky() {
      let sticky = this.sticky;
      if (
        this.module === "listing" ||
        this.module === "property" ||
        this.module === "propertyDeveloper"
      ) {
        if (!this.content) {
          this.content = document.getElementById(this.module + "-content");
        }
        if (window.pageYOffset > sticky) {
          if (this.topMargin) {
            this.content.style.marginTop = this.topMargin;
          } else {
            if (this.content && this.content.style) {
              this.content.style.marginTop = "0";
            }
          }
          this.header.classList.add("sticky");
        } else if (window.pageYOffset < sticky) {
          this.header.classList.remove("sticky");
          if (this.content && this.content.style) {
            this.content.style.marginTop = "0";
          }
        }
      } else {
        if (window.pageYOffset > sticky) {
          this.header.classList.add("sticky");
          if (!this.content) return;
          if (this.topMargin) {
            this.content.style.marginTop = this.topMargin;
          } else {
            this.content.style.marginTop = "66px";
          }
        } else if (window.pageYOffset < sticky) {
          this.header.classList.remove("sticky");
          if (!this.content) return;
          this.content.style.marginTop = "0";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-padding-24 {
  padding-left: 24px !important;
}

.no-padding {
  padding: 0 !important;
}
</style>
