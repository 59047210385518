<template>
  <div class="dashboard-wrapper">
    <Header
      :user-dashboards="dashboardIds"
      @openAddWidget="openAddWidgetModal"
      @dashboardUpdated="getDashboards"
    />
    <Content
      :widget-modal-opened="addWidgetModalOpened"
      :user-dashboard="userDashboard"
      @closeModal="handleCloseModal"
      @updateDashboard="getDashboards"
    />
  </div>
</template>

<script>
import Header from "@/components/Dashboard/Header.vue";
import Content from "@/components/Dashboard/Content.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Dashboard",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      addWidgetModalOpened: false,
      dashboardIds: [],
      userDashboard: {}
    };
  },
  async created() {
    await this.getDashboards();
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    openAddWidgetModal() {
      this.addWidgetModalOpened = true;
    },
    handleCloseModal() {
      this.addWidgetModalOpened = false;
    },
    async getDashboards() {
      const response = await axios.get(
        `/api/dashboard/get-dashboards/${this.user._id}`
      );
      if (response.status === 200) {
        this.dashboardIds = response.data.dashboardIds
        this.userDashboard = response.data.defaultDashboard;
      }
    },
  },
};
</script>

<style lang="scss"></style>
