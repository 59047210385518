<template>
  <div class="widget-wrapper">
    <div class="users-contacts-count">
      <div class="users-contacts-count">
        <div
          class="user-contacts-field"
          v-for="user in filteredUsersContacts"
          :key="user._id"
          :class="{ first: user.ranking === 1 }"
        >
          <div class="user-contact-left">
            <div class="user-ranking-bg" :class="{ first: user.ranking === 1 }">
              {{ user.ranking ? user.ranking : 0 }}
            </div>
            <div class="user-credentials">
              <div class="user-name">
                {{ user.name }}
              </div>
              <div class="users-detail" :class="{ first: user.ranking === 1 }">{{ user.defaultGroup }}</div>
            </div>
          </div>
          <div class="users-contact-right" :class="{ first: user.ranking === 1 }">
            {{ user.count }}
          </div>
        </div>
      </div>
    </div>
    <div class="user-contact-count">
      <div class="user-contacts-field">
        <div class="user-contact-left">
          <div class="user-ranking-bg">{{ userClientCount.ranking }}</div>
          <div class="user-credentials">
            <div class="user-name">
              {{ userClientCount.name }} ({{ $t("You") }})
            </div>
            <div class="user-detail">{{ userClientCount.defaultGroup }}</div>
          </div>
        </div>
        <div class="user-contact-right">
          {{ userClientCount.count }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ContactsAdded",
  components: {},
  props: {
    widgetId: {
      type: String,
      required: true,
    },
    filteredUsers: {
      type: Array,
      default: undefined,
    },
    selectedPeriod: {}
  },
  watch: {
    selectedPeriod: {
      async handler() {
        await this.fetchContactsCount();
      },
      deep: true
    },
    colleagues: {
      handler: "filterContactsCountByColleagues",
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      userClientCount: {},
      usersClientCount: [],
      filteredUsersContacts: [],
    };
  },
  async mounted() {
    await this.fetchContactsCount();
  },
  computed: {
    ...mapGetters(["user", "users"]),
    colleagues() {
      return this.filteredUsers || this.users;
    },
  },
  methods: {
    async fetchContactsCount() {
      try {
        const response = await axios.post(
          `/api/contact/added-clients-by-period/${this.user._id}`,
          {
            period: this.selectedPeriod?.value,
          }
        );
        if (response && response.data && this.widgetId) {
          let userCount = response.data.userCount;
          let usersCount = response.data.allUsersCount;

          this.userClientCount = userCount;
          this.usersClientCount = usersCount;
          this.filterContactsCountByColleagues();
          this.$emit("contentLoaded", this.widgetId);
        }
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
    },
    filterContactsCountByColleagues() {
      if (this.colleagues && this.colleagues.length > 0) {
        this.filteredUsersContacts = this.usersClientCount.filter((user) =>
          this.colleagues.some((colleague) => colleague._id === user._id)
        );
      } else {
        this.filteredUsersContacts = [...this.usersClientCount];
      }

      this.filteredUsersContacts.sort((a, b) => b.count - a.count);
      let currentRank = 1;
      for (let i = 0; i < this.filteredUsersContacts.length; i++) {
        if (
          i > 0 &&
          this.filteredUsersContacts[i].count ===
            this.filteredUsersContacts[i - 1].count
        ) {
          this.filteredUsersContacts[i].ranking =
            this.filteredUsersContacts[i - 1].ranking;
        } else if (this.filteredUsersContacts[i].count === 0) {
          this.filteredUsersContacts[i].ranking = "-";
        } else {
          this.filteredUsersContacts[i].ranking = currentRank;
        }
        currentRank++;
      }
      if (this.userClientCount.count > 0) {
        this.userClientCount.ranking = this.calculateUserRanking();
      } else {
        this.userClientCount.ranking = "-";
      }
    },
    calculateUserRanking() {
      const userInFiltered = this.filteredUsersContacts.some(
        (user) => user._id === this.user._id
      );
      if (!userInFiltered) {
        return "-";
      }
      let rank = 1;
      let userFound = false;
      for (const user of this.filteredUsersContacts) {
        if (user.count === 0) {
          continue;
        }
        if (user._id === this.user._id) {
          userFound = true;
          break;
        }
        if (!userFound && user.count > this.userClientCount.count) {
          rank++;
        }
      }
      return userFound
        ? this.filteredUsersContacts.find((user) => user._id === this.user._id)
            .ranking
        : rank;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-contact-count {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #e6e8ec;
}

.user-contacts-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(244, 245, 247);
}

.user-contacts-field.first {
  background-color: rgba(255, 240, 240, 1);
}

.user-contact-left {
  display: flex;
  gap: 8px;
}

.user-contact-right,
.users-contact-right {
  font-family: Geomanist;
  font-size: 18px;
  font-weight: 700;
}

.users-contact-right.first {
  color: rgba(255, 92, 1, 1) !important;
}

.users-contacts-count {
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.widget-wrapper {
  display: flex;
  flex-direction: column;
  height: 366px;
}

.user-detail,
.users-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.users-detail.first {
  color: rgba(255, 92, 1, 1) !important;
}

.user-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.user-ranking-bg {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: rgb(230, 232, 236);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 4px 0 0 0;
  font-weight: 500;
  color: #939597;
  font-family: Geomanist;
}

.user-ranking-bg.first {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}
</style>
