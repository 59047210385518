var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    staticClass: "dashboard-dropdown-wrapper",
    class: {
      dashboardOpened: _vm.dropdownOpen
    }
  }, [_c('div', {
    staticClass: "dashboard-dropdown-button",
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('div', {
    staticClass: "selected-dashboard-wrapper"
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("@/assets/images/bookmark.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "button-small ellipsis-text"
  }, [_vm._v(" " + _vm._s(_vm.selectedDashboards) + " ")])]), _c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("@/assets/images/chevron-disabled.svg"),
      "alt": ""
    }
  })]), _vm.dropdownOpen ? _c('div', {
    staticClass: "dropdown-items-wrapper"
  }, [_vm._l(_vm.value, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.selectDashboard(item);
        }
      }
    }, [_c('div', {
      staticClass: "checkmark-wrapper"
    }, [item.isDefault ? _c('img', {
      staticClass: "img16",
      attrs: {
        "src": require("@/assets/images/checkmark.svg"),
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {}, [_vm._v(_vm._s(item.isPermanent ? _vm.$t(item.name) : item.name))])]);
  }), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "dropdown-item",
    on: {
      "click": _vm.openAddDashboard
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require(`@/assets/images/plus.svg`),
      "alt": ""
    }
  }), _c('div', {}, [_vm._v(" " + _vm._s(_vm.$t("AddDashboard")) + " ")])]), _c('div', {
    staticClass: "dropdown-item",
    on: {
      "click": _vm.deleteDashboard
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require(`@/assets/images/TrashBin.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "delete-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("DeleteDashboard")) + " ")])])], 2) : _vm._e(), _vm.isAddDashboard ? _c('div', {
    staticClass: "add-dashboard-modal"
  }, [_c('AddDashboardModal', {
    on: {
      "dashboardCreated": _vm.handleDashboardCreated,
      "cancel": _vm.handleCancel
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }