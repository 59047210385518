var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: {
      dropdown: !_vm.disabled,
      'dropdown dropdown-disabled': _vm.disabled
    },
    style: _vm.customStyle
  }, [_c('div', {
    staticClass: "dropbtn",
    class: {
      'dropbtn dropbtn-hover': !_vm.disabled,
      dropbtn: _vm.disabled
    },
    style: _vm.customStyleButton,
    on: {
      "keydown": _vm.searchItem,
      "click": _vm.openDropdown
    }
  }, [_c('div', {
    staticClass: "label-and-item-wrapper"
  }, [_vm.placeholderText ? _c('div', {
    staticClass: "field-label"
  }, [_c('p', {
    staticClass: "field-label-text",
    style: !_vm.selectedItem ? 'font-size: 16px' : null
  }, [_vm._v(" " + _vm._s(_vm.placeholderText) + " ")])]) : _vm._e(), _vm.defaultItem && _vm.defaultItem === _vm.selectedItem ? _c('p', {
    class: {
      'dropbtn-text regular-size-text default-item': _vm.regularSize,
      'dropbtn-text small-size-text default-item': !_vm.regularSize
    }
  }, [_vm._v(" " + _vm._s(_vm.defaultItem.name) + " ")]) : _vm.selectedItem ? _c('p', {
    class: {
      'dropbtn-text regular-size-text': _vm.regularSize,
      'dropbtn-text small-size-text': !_vm.regularSize
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedItem.name) + " ")]) : _vm._e()]), _c('img', {
    class: {
      'dropbtn-arrow': !_vm.disabled,
      'dropbtn-arrow arrow-disabled': _vm.disabled
    },
    attrs: {
      "src": require("../../assets/images/chevron-down.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dropdown-content dropdown-single",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": _vm.dropdownId
    }
  }, [_vm.searchBox ? _c('div', {
    staticClass: "search-wrapper"
  }, [_vm.dropdownOpen ? _c('InputSmall', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.$t('Search'),
      "type": 'text',
      "auto-focus": true
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._l(_vm.dropdownItems, function (item, index) {
    return _c('div', {
      key: item._id,
      class: {
        'dropdown-item-wrapper': !item.inFocus,
        'dropdown-item wrapper dropdown-item-focused': item.inFocus
      },
      attrs: {
        "id": _vm.dropdownId + '-dropdown-item-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_c('div', {
      staticClass: "checkmark-wrapper"
    }, [_vm.selectedItem && item[_vm.valueKey] === _vm.selectedItem[_vm.valueKey] ? _c('img', {
      staticStyle: {
        "filter": "invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg)"
      },
      attrs: {
        "src": require("../../assets/images/checkmark.svg"),
        "alt": ""
      }
    }) : _vm._e()]), _c('p', {
      class: {
        'not-selected': _vm.selectedItem && item[_vm.valueKey] !== _vm.selectedItem[_vm.valueKey] || !_vm.selectedItem,
        selected: _vm.selectedItem && item[_vm.valueKey] === _vm.selectedItem[_vm.valueKey],
        'regular-size-text': _vm.regularSize,
        'small-size-text': !_vm.regularSize
      }
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")])]);
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }