<template>
  <div class="widget-wrapper">
    <div class="users-count">
      <div class="users-count">
        <div
          class="user-field"
          v-for="user in filteredUsersTurnover"
          :key="user.userId"
          :class="{ first: user.ranking === 1 }"
        >
          <div class="user-left">
            <div class="user-ranking-bg" :class="{ first: user.ranking === 1 }">
              {{ user.ranking ? user.ranking : 0 }}
            </div>
            <div class="user-credentials">
              <div class="user-name">
                {{ user.name }}
              </div>
              <div class="users-detail" :class="{ first: user.ranking === 1 }">
                {{ user.defaultGroup }}
              </div>
            </div>
          </div>
          <div class="users-right" :class="{ first: user.ranking === 1 }">
            {{ user.revenue > 0 ? formatNumber(user.revenue) + " €" : 0 }}
          </div>
        </div>
      </div>
    </div>
    <div class="user-count">
      <div class="user-field">
        <div class="user-left">
          <div class="user-ranking-bg">
            {{ userTurnover.ranking ? userTurnover.ranking : "-" }}
          </div>
          <div class="user-credentials">
            <div class="user-name">
              {{ userTurnover.name }} ({{ $t("You") }})
            </div>
            <div class="user-detail">{{ userTurnover.defaultGroup }}</div>
          </div>
        </div>
        <div class="user-right">
          {{
            userTurnover.revenue > 0
              ? formatNumber(userTurnover.revenue) + " €"
              : 0
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "TurnoverTop",
  components: {},
  props: {
    widgetId: {
      type: String,
      required: true,
    },
    filteredUsers: {
      type: Array,
      default: undefined,
    },
    selectedPeriod: {},
  },
  watch: {
    selectedPeriod: {
      async handler() {
        await this.fetchTurnover();
      },
      deep: true,
    },
    colleagues: {
      handler: "filterTurnoverByColleagues",
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      userTurnover: {},
      usersTurnover: [],
      filteredUsersTurnover: [],
    };
  },
  async mounted() {
    await this.fetchTurnover();
  },
  computed: {
    ...mapGetters(["user"]),
    colleagues() {
      return this.filteredUsers;
    },
  },
  methods: {
    async fetchTurnover() {
      try {
        const response2 = await axios.post("/api/widget/get-revenue-leaderboard", {
          period: this.selectedPeriod?.value,
        });
        console.log('response2: ', response2)
        if (response2 && response2.data && this.widgetId) {
          this.userTurnover = response2.data.userTurnover || ''
          this.usersTurnover = response2.data.turnoverLeaderboard;
          this.$emit("contentLoaded", this.widgetId);
          this.filterTurnoverByColleagues();
        }
        console.log('response2.data',response2?.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    filterTurnoverByColleagues() {
      if (this.colleagues && this.colleagues.length > 0) {
        this.filteredUsersTurnover = this.usersTurnover.filter((user) =>
          this.colleagues.some((colleague) => colleague._id === user.userId)
        );
      } else {
        this.filteredUsersTurnover = [...this.usersTurnover];
      }
      this.filteredUsersTurnover.sort((a, b) => b.revenue - a.revenue);

      let currentRank = 1;
      for (let i = 0; i < this.filteredUsersTurnover.length; i++) {
        if (
          i > 0 &&
          this.filteredUsersTurnover[i].turnover ===
            this.filteredUsersTurnover[i - 1].revenue
        ) {
          this.filteredUsersTurnover[i].ranking =
            this.filteredUsersTurnover[i - 1].ranking;
        } else if (this.filteredUsersTurnover[i].revenue === 0) {
          this.filteredUsersTurnover[i].ranking = "-";
        } else {
          this.filteredUsersTurnover[i].ranking = currentRank;
        }
        currentRank++;
      }
      if (this.userTurnover.revenue > 0) {
        this.userTurnover.ranking = this.calculateUserRanking();
      } else {
        this.userTurnover.ranking = "-";
      }
    },
    calculateUserRanking() {
      const userInFiltered = this.filteredUsersTurnover.some(
        (user) => user.userId === this.user._id
      );
      if (!userInFiltered) {
        return "-";
      }
      let rank = 1;
      let userFound = false;
      for (const user of this.filteredUsersTurnover) {
        if (user.revenue === 0) {
          continue;
        }
        if (user.userId === this.user._id) {
          userFound = true;
          break;
        }
        if (!userFound && user.revenue > this.userTurnover.revenue) {
          rank++;
        }
      }
      return userFound
        ? this.filteredUsersTurnover.find((user) => user.userId === this.user._id)
            .ranking
        : rank;
    },
    formatNumber(value) {
      let formattedNumber = value.toFixed(0);
      formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      return formattedNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-count {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #e6e8ec;
}

.user-field {
  border-radius: 12px;
  padding: 6px 14px;
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(244, 245, 247);
}

.user-field.first {
  background-color: rgba(255, 240, 240, 1);
}

.user-left {
  display: flex;
  gap: 8px;
}

.user-right,
.users-right {
  font-family: Geomanist;
  font-size: 18px;
  font-weight: 700;
}

.users-right.first {
  color: rgba(255, 92, 1, 1) !important;
}

.users-count {
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.widget-wrapper {
  display: flex;
  flex-direction: column;
  height: 366px;
}

.user-detail,
.users-detail {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(147, 149, 151, 1);
}

.users-detail.first {
  color: rgba(255, 92, 1, 1) !important;
}

.user-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Geomanist;
}

.user-ranking-bg {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: rgb(230, 232, 236);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 4px 0 0 0;
  font-weight: 500;
  color: #939597;
  font-family: Geomanist;
}

.user-ranking-bg.first {
  background-color: rgba(255, 92, 1, 1) !important;
  color: white !important;
}
</style>
