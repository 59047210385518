var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-wrapper"
  }, [_c('div', {
    staticClass: "users-count"
  }, [_c('div', {
    staticClass: "users-count"
  }, _vm._l(_vm.filteredUsersTurnover, function (user) {
    return _c('div', {
      key: user.userId,
      staticClass: "user-field",
      class: {
        first: user.ranking === 1
      }
    }, [_c('div', {
      staticClass: "user-left"
    }, [_c('div', {
      staticClass: "user-ranking-bg",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.ranking ? user.ranking : 0) + " ")]), _c('div', {
      staticClass: "user-credentials"
    }, [_c('div', {
      staticClass: "user-name"
    }, [_vm._v(" " + _vm._s(user.name) + " ")]), _c('div', {
      staticClass: "users-detail",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.defaultGroup) + " ")])])]), _c('div', {
      staticClass: "users-right",
      class: {
        first: user.ranking === 1
      }
    }, [_vm._v(" " + _vm._s(user.revenue > 0 ? _vm.formatNumber(user.revenue) + " €" : 0) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "user-count"
  }, [_c('div', {
    staticClass: "user-field"
  }, [_c('div', {
    staticClass: "user-left"
  }, [_c('div', {
    staticClass: "user-ranking-bg"
  }, [_vm._v(" " + _vm._s(_vm.userTurnover.ranking ? _vm.userTurnover.ranking : "-") + " ")]), _c('div', {
    staticClass: "user-credentials"
  }, [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(" " + _vm._s(_vm.userTurnover.name) + " (" + _vm._s(_vm.$t("You")) + ") ")]), _c('div', {
    staticClass: "user-detail"
  }, [_vm._v(_vm._s(_vm.userTurnover.defaultGroup))])])]), _c('div', {
    staticClass: "user-right"
  }, [_vm._v(" " + _vm._s(_vm.userTurnover.revenue > 0 ? _vm.formatNumber(_vm.userTurnover.revenue) + " €" : 0) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }