var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', {
    ref: "searchInputDropdown",
    attrs: {
      "name": _vm.name,
      "id": _vm.id + 'input-small',
      "type": _vm.type,
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "keyup": function ($event) {
        return _vm.$emit('keyUp');
      },
      "change": function ($event) {
        return _vm.$emit('change');
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }