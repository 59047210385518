var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('GridContent', {
    attrs: {
      "added-widgets": _vm.addedWidgets,
      "user-dashboard": _vm.userDashboard
    },
    on: {
      "addWidgets": _vm.showAddWidgetModal
    }
  }), _vm.isWidgetModal ? _c('AddWidgetModal', {
    on: {
      "closeWidgetModal": _vm.handleCloseModal,
      "addWidgets": _vm.handleAddWidgets
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }